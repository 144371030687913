import React from 'react';
import '../layouts/Qualidade.css';

import QualidadeGallery from '../components/QualidadeGallery';


const Qualidade = () => {
    return (
        <div className='main-content'>
            <section className="qualidade-container">
                <div className="banner-logo-quali">
                    {/*<img src={bannerlogo} alt="Logo Prodemol" />*/}
                </div>
                <h2 className="qualidade-title">Nossos Certificados</h2>
                <p>
                    O grupo prodemol moldes, atuando no mercado desde 1995, é
                    especializado em oferecer soluções completas na área de
                    manufatura, com um portfólio de serviços que inclui:
                </p>
                <ul>
                    <li>
                        Usinagem e programação cad-cam: serviços de usinagem de
                        alta precisão, com programação avançada utilizando software
                        cad-cam para otimização de processos.
                    </li>
                    <li>
                        Manutenção, modificação e revitalização de moldes de injeção.
                    </li>
                    <li>
                        Construção de moldes: desenvolvimento e fabricação de
                        moldes para termoplásticos, termoformados e moldes de
                        sopro, atendendo às especificações técnicas e exigências de
                        qualidade dos nossos clientes.
                    </li>
                    <li>
                        Dispositivos de inspeção: criação de dispositivos de inspeção
                        para garantir que os produtos atendam aos padrões de
                        qualidade estabelecidos.
                    </li>
                </ul>
                <p>
                    A integração desses serviços permite oferecer soluções
                    personalizadas e eficientes, com foco no atendimento às
                    necessidades do setor automobilístico e de autopeças.
                </p>
                <h3>Nossa política da qualidade</h3>
                <p>
                    A satisfação do cliente é o compromisso central de nossa
                    política de qualidade. buscamos a excelência contínua em todos
                    os nossos processos e serviços, com o objetivo de atender às
                    expectativas e requisitos dos nossos clientes de maneira
                    consistente e eficaz.
                </p>
                <p>
                    Para alcançar esse objetivo, o grupo prodemol moldes se
                    compromete a:
                </p>
                <ol>
                    <li>
                        Fornecer produtos e serviços de alta qualidade, alinhados
                        às especificações e necessidades dos nossos clientes.
                    </li>
                    <li>
                        Garantir pontualidade nas entregas, atendendo aos prazos
                        estabelecidos e à confiança depositada pelos nossos clientes.
                    </li>
                    <li>
                        Manter parcerias com fornecedores que compartilhem dos
                        nossos valores e atendam aos nossos critérios de qualidade,
                        contribuindo para a melhoria contínua de nossos processos.
                    </li>
                    <li>
                        Investir na melhoria contínua de nossos produtos,
                        processos e sistema de gestão da qualidade, visando sempre o
                        aprimoramento da nossa organização e a superação das
                        expectativas dos nossos clientes.
                    </li>
                </ol>
                <p>
                    Nosso compromisso com a qualidade é respaldado pela melhoria
                    contínua e pela busca incessante pela excelência, assegurando
                    que o grupo prodemol moldes se mantenha como referência no
                    mercado, sempre alinhado às necessidades de nossos clientes e
                    às exigências do setor.
                </p>
                <QualidadeGallery />
            </section>
        </div>
    );
};

export default Qualidade;